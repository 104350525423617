<script>
import { mapState, mapActions } from 'vuex';
import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  data(){
    return {
      chartdata: null
    };
  },
  props: {
    options: {
      type: Object,
      default: null
    }
  },
  watch: {
    'currentChartData': function(){
      this.chartdata = this.currentChartData;
      this.renderChart(this.chartdata, this.options);
    }
  },
  computed: mapState({
    currentChartData: state => state.chart.currentChartData,
  }),
}
</script>
